import Button from './Button';
import classNames from "classnames";

function VidtaoButton({ children, className, ...rest }) {
  const classes = classNames('px-6 py-8 sm:px-8 sm:py-9 font-bold text-xl sm:text-3xl', className);

  return (
    <Button {...rest} className={classes} primary rounded>{children}</Button>
  );
}

export default VidtaoButton;
