import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { SERVER_API } from '../../constants'

const submitEmail = createAsyncThunk('landing/email', async ({ uid, email }) => {
  return axios.post(`${SERVER_API.BASE_URL}/add-user`, { user_id: uid, email }, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    withCredentials: true
  })
    .then(response => {
      return { data: response.data };
    })
    .catch(error => {
      if (error.response.data) {
        return { error: error.response.data };
      } else {
        return { error };
      }
    });
});

export { submitEmail };
