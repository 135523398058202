import video_not_found from '../../images/video_not_found.jpg';

function VideoDetailsCheckbox({ adVideo, name, ...rest }) {

  const addUnitToNumber = input => {
    let num = Number(input);
    if (num) {
      const units = ["", "Thousand", "Million", "Billion", "Trillion"]
      let unit = units[0];
      let numWithUnit = "";
      let unitLevel = 0;
      while (num >= 1000 && unitLevel < units.length - 1) {
        num /= 1000;
        unitLevel++;
        unit = units[unitLevel];
      }
      numWithUnit = unitLevel > 0 ? (Math.ceil(num * 10) / 10).toString() + " " + unit : num.toString();
      return numWithUnit;
    } else {
      return input;
    }
  };

  return (
    <label className="m-5">
      <div className="flex flex-row justify-start items-start">
        {name && <input className="mt-1 mr-2 sm:mr-5 square-radio" type="radio" name={name} value={adVideo[0]} disabled={!adVideo[6]} {...rest} />}
        <img className="w-20 sm:w-36 shrink-0" src={adVideo[1] || video_not_found} alt="video thumbnail" />
        <div className="flex flex-col ml-2 sm:ml-5 font-bold text-sm sm:text-base break-words">
          <p>{adVideo[2]}</p>
          <p>{adVideo[3]}</p>
          <p className="font-normal underline text-[#0097A7] break-all"><a href={adVideo[4]} target="_blank" rel="noreferrer">{adVideo[4]}</a></p>
          <p>Views: {addUnitToNumber(adVideo[5])}</p>
        </div>
      </div>
    </label>
  );
}

export default VideoDetailsCheckbox;
