import { createSlice } from '@reduxjs/toolkit';
import { getAnalysisReport } from '../index';

const defaultErrorMessage = 'Oops... Something went wrong. Please try again later.';

const analysisSlice = createSlice({
  name: 'analysis',
  initialState: {
    isWaiting: false,
    isReportLoaded: false,
    errorMessage: null,
    videoInfoItems: null,
    creativeAnalysisTextItems: null,
    screenshotSeries: null,
    hookEvaluationResults: null
  },
  extraReducers(builder) {
    builder.addCase(getAnalysisReport.pending, (state, action) => {
      state.isWaiting = true;
    });
    builder.addCase(getAnalysisReport.fulfilled, (state, action) => {
      const { data, error } = action.payload;
      state.isWaiting = false;
      if (data) {
        if (data.video_info_items || data.creative_analysis_text_items || data.screenshot_series || data.hook_evaluation_results) {
          state.videoInfoItems = data.video_info_items;
          state.creativeAnalysisTextItems = data.creative_analysis_text_items;
          state.screenshotSeries = data.screenshot_series;
          state.hookEvaluationResults = data.hook_evaluation_results;
          state.isReportLoaded = true;
        } else {
          state.errorMessage = "Failed to build your report. We're sorry about that.";
        }
      } else if (error) {
        state.errorMessage = error.error;
      } else {
        state.errorMessage = "Unknown error.";
      }
    });
    builder.addCase(getAnalysisReport.rejected, (state, action) => {
      state.isWaiting = false;
      state.errorMessage = defaultErrorMessage
    });
  }
});

export const analysisReducer = analysisSlice.reducer;
