export const hookTactics = [
  {
    title: "👁️ Hook Tactic #1: Use Visual Urgency",
    howToMarkdown: "Incorporate visually urgent elements like unexpected or emergency situations (e.g., a grill on fire) to immediately capture the viewer's attention.",
    inActionMarkdown: "This classic ad from Prepared Hero has spent close to $500k in the past 30 days. Watch the struggle with the grill fire in these first 10 seconds:",
    videoId: "mWEooljXO5Q",
    promoMarkdown: `Ad: [https://youtu.be/mWEooljXO5Q](https://youtu.be/mWEooljXO5Q)\\
    \\
    Want to see more great ads? See all of Prepared Hero’s ads & landing pages [here inside VidTao 2.0](https://flight.beehiiv.net/v2/clicks/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJodHRwczovL2FwcC52aWR0YW8uY29tLyMvZGFzaGJvYXJkL2VudGl0eS1pbmZvP2JyYW5kSWQ9MTY1MTU2JmtleXdvcmQ9cHJlcGFyZWQlMjBoZXIiLCJwb3N0X2lkIjoiNmI2Yjk3OTMtMzBmOS00ZjFiLTkwYzYtNzM2MDEyNWIyN2NmIiwicHVibGljYXRpb25faWQiOiI1MTUyNzExYi0yMWY3LTRlOTAtYjg1OC0zMzlhNmY0MmI3MmMiLCJ2aXNpdF90b2tlbiI6IjVmMTAwNTM5LTUyMDktNDZmYi1iMDQ3LWU0ZDYzZTU1MTA2ZSIsImlhdCI6MTcwMTE1MTc4NiwiaXNzIjoib3JjaGlkIn0.kBiOY_JRW0mNPj_tH0EP1pmYt1Yzyk1cMakWueTtLU4)`
  }, {
    title: "🔊 Hook Tactic #2: Audio Shock Factor",
    howToMarkdown: "Utilize surprising or dramatic audio exclamations to enhance the sense of urgency and keep viewers engaged.",
    inActionMarkdown: "Listen to the panicked “Oh my Gosh!” exclamation in the same Prepared Hero ad:",
    videoId: "mWEooljXO5Q",
    promoMarkdown: `Ad: [https://youtu.be/mWEooljXO5Q](https://youtu.be/mWEooljXO5Q)\\
    \\
    Want to see more great ads? See all of Prepared Hero’s ads & landing pages [here inside VidTao 2.0](https://flight.beehiiv.net/v2/clicks/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJodHRwczovL2FwcC52aWR0YW8uY29tLyMvZGFzaGJvYXJkL2VudGl0eS1pbmZvP2JyYW5kSWQ9MTY1MTU2JmtleXdvcmQ9cHJlcGFyZWQlMjBoZXIiLCJwb3N0X2lkIjoiNmI2Yjk3OTMtMzBmOS00ZjFiLTkwYzYtNzM2MDEyNWIyN2NmIiwicHVibGljYXRpb25faWQiOiI1MTUyNzExYi0yMWY3LTRlOTAtYjg1OC0zMzlhNmY0MmI3MmMiLCJ2aXNpdF90b2tlbiI6IjVmMTAwNTM5LTUyMDktNDZmYi1iMDQ3LWU0ZDYzZTU1MTA2ZSIsImlhdCI6MTcwMTE1MTc4NiwiaXNzIjoib3JjaGlkIn0.kBiOY_JRW0mNPj_tH0EP1pmYt1Yzyk1cMakWueTtLU4)`
  }, {
    title: "👩‍🏫 Hook Tactic #3: Instructional Setting",
    howToMarkdown: "Use visuals that set an educational tone, such as a presenter in front of a whiteboard, to position the ad as informative or instructional.",
    inActionMarkdown: "Watch this Hook Tactic “out in the wild” with this ad from Acorns, **with over $4 million usd** in estimated adspend:",
    videoId: "IqhMMLYHGVs",
    promoMarkdown: `Ad: [https://youtu.be/IqhMMLYHGVs](https://youtu.be/IqhMMLYHGVs)\\
    \\
    See all of Acorns’ ads & landing pages [here inside VidTao 2.0](https://flight.beehiiv.net/v2/clicks/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJodHRwczovL2FwcC52aWR0YW8uY29tLyMvZGFzaGJvYXJkL2VudGl0eS1pbmZvP2JyYW5kSWQ9MjM3MzYyJmtleXdvcmQ9YWNvcm4iLCJwb3N0X2lkIjoiNmI2Yjk3OTMtMzBmOS00ZjFiLTkwYzYtNzM2MDEyNWIyN2NmIiwicHVibGljYXRpb25faWQiOiI1MTUyNzExYi0yMWY3LTRlOTAtYjg1OC0zMzlhNmY0MmI3MmMiLCJ2aXNpdF90b2tlbiI6IjVmMTAwNTM5LTUyMDktNDZmYi1iMDQ3LWU0ZDYzZTU1MTA2ZSIsImlhdCI6MTcwMTE1MTc4NiwiaXNzIjoib3JjaGlkIn0.5QfULVMKzz7hWHRuXTyNjxNj4GsD6OL7_pyQn03U2fw).`
  }, {
    title: "🚩 Hook Tactic #4: Address the Problem Directly",
    howToMarkdown: "Start the audio with a direct mention of a common problem or mistake that the target audience can relate to, fostering immediate connection and interest.",
    inActionMarkdown: "Listen to 30 Day Singer’s opening line: “Can anyone learn to sing? Or is it something you’re just born with?”",
    videoId: "ZMhr5AnSlb4",
    promoMarkdown: "See [all of 30 Day Singer’s ads & landing pages here](https://flight.beehiiv.net/v2/clicks/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJodHRwczovL2FwcC52aWR0YW8uY29tLyMvZGFzaGJvYXJkL2VudGl0eS1pbmZvP2JyYW5kSWQ9MTI0MjI2JmtleXdvcmQ9MzAlMjBkYXkiLCJwb3N0X2lkIjoiNmI2Yjk3OTMtMzBmOS00ZjFiLTkwYzYtNzM2MDEyNWIyN2NmIiwicHVibGljYXRpb25faWQiOiI1MTUyNzExYi0yMWY3LTRlOTAtYjg1OC0zMzlhNmY0MmI3MmMiLCJ2aXNpdF90b2tlbiI6IjVmMTAwNTM5LTUyMDktNDZmYi1iMDQ3LWU0ZDYzZTU1MTA2ZSIsImlhdCI6MTcwMTE2MzMzNiwiaXNzIjoib3JjaGlkIn0.reWbz1cTHcNqg6N4-mkguQNRILbuuxA5ABFWt83G6Kw) inside VidTao 2.0:"
  }, {
    title: "🎨 Hook Tactic #5: Visual Contrast and Provocation",
    howToMarkdown: "Employ striking and unconventional visuals to provoke interest and differentiate from typical imagery.",
    inActionMarkdown: "An appropriate before / after shot (like in this Jawzercise video with an estimated $4M+ in adspend) is a great use of Visual Contrast:",
    videoId: "UBw_XDsW6NI",
    promoMarkdown: `Ad: [https://youtu.be/UBw_XDsW6NI](https://youtu.be/UBw_XDsW6NI)\\
    \\
    See all of Jawzercise’s ads & landing pages [here inside VidTao 2.0](https://flight.beehiiv.net/v2/clicks/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJodHRwczovL2FwcC52aWR0YW8uY29tLyMvZGFzaGJvYXJkL2VudGl0eS1pbmZvP2JyYW5kSWQ9MjcxMjIma2V5d29yZD1qYXciLCJwb3N0X2lkIjoiNmI2Yjk3OTMtMzBmOS00ZjFiLTkwYzYtNzM2MDEyNWIyN2NmIiwicHVibGljYXRpb25faWQiOiI1MTUyNzExYi0yMWY3LTRlOTAtYjg1OC0zMzlhNmY0MmI3MmMiLCJ2aXNpdF90b2tlbiI6IjllMjVkMTdkLWI3NzYtNDJjZi1iY2NiLWJlZTNiNTc4MTMwZiIsImlhdCI6MTcwMTE1NjkzMiwiaXNzIjoib3JjaGlkIn0.jjC5mzfcHAzoOfROe69GK3CTDaR5T13vut-JNgzU3kI):`
  }, {
    title: "🤔 Hook Tactic #6: Intriguing Audio Claim",
    howToMarkdown: "Use audio to make intriguing or challenging claims (e.g., about common misconceptions), which can pique curiosity and encourage further viewing.",
    inActionMarkdown: "Listen to the CLASSIC “Are Vegans Healthy? Are Vegetarians healthy?” opening question line in this Organifi ad with over $12 million usd in estimated adspend:",
    videoId: "Q1c6PmxZ2ZM",
    promoMarkdown: `Ad: [https://youtu.be/Q1c6PmxZ2ZM](https://youtu.be/Q1c6PmxZ2ZM)\\
    \\
    See [all of Organifi’s ads & landing pages](https://flight.beehiiv.net/v2/clicks/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJodHRwczovL2FwcC52aWR0YW8uY29tLyMvZGFzaGJvYXJkL2VudGl0eS1pbmZvP2JyYW5kSWQ9MTI2MTU4JmtleXdvcmQ9Zml0bGlmZSIsInBvc3RfaWQiOiI2YjZiOTc5My0zMGY5LTRmMWItOTBjNi03MzYwMTI1YjI3Y2YiLCJwdWJsaWNhdGlvbl9pZCI6IjUxNTI3MTFiLTIxZjctNGU5MC1iODU4LTMzOWE2ZjQyYjcyYyIsInZpc2l0X3Rva2VuIjoiOWUyNWQxN2QtYjc3Ni00MmNmLWJjY2ItYmVlM2I1NzgxMzBmIiwiaWF0IjoxNzAxMTU2OTMyLCJpc3MiOiJvcmNoaWQifQ.ETaIKyA3y6xY6iX4pJCeZODCgrwLFdysh7cONriCqcg) here inside VidTao 2.0.`
  }, {
    title: "🍂 Hook Tactic #7: Transformational Visual",
    howToMarkdown: "Show a visual transformation (like a metamorphosis, etc.) to highlight the product’s practical benefits and its impact on the user.",
    inActionMarkdown: "Watch the subject in this BaerSkin Hoodie’s transformation from over-layered and uncomfortable to a single hoodie, comfortable and stylish:",
    videoId: "sUn-0M91-48",
    promoMarkdown: `Ad: [https://youtu.be/sUn-0M91-48](https://youtu.be/sUn-0M91-48)\\
    \\
    See all of Baerskin’s ads & landing pages [here inside VidTao 2.0](https://flight.beehiiv.net/v2/clicks/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJodHRwczovL2FwcC52aWR0YW8uY29tLyMvZGFzaGJvYXJkL2VudGl0eS1pbmZvP2JyYW5kSWQ9MTE5MzY1JmtleXdvcmQ9YmFlciIsInBvc3RfaWQiOiI2YjZiOTc5My0zMGY5LTRmMWItOTBjNi03MzYwMTI1YjI3Y2YiLCJwdWJsaWNhdGlvbl9pZCI6IjUxNTI3MTFiLTIxZjctNGU5MC1iODU4LTMzOWE2ZjQyYjcyYyIsInZpc2l0X3Rva2VuIjoiOWUyNWQxN2QtYjc3Ni00MmNmLWJjY2ItYmVlM2I1NzgxMzBmIiwiaWF0IjoxNzAxMTU2OTMyLCJpc3MiOiJvcmNoaWQifQ.SpaRbHi8xLBZeD4QdhdXWDlxlnd4XYtMyfWhDgJw6WE).`
  }, {
    title: "🔴 Hook Tactic #8: Problem Identification in Audio",
    howToMarkdown: "Begin with audio that identifies a relatable problem, setting the stage for the product as a solution.",
    inActionMarkdown: "Writing’s not that easy…” as the opening lines in this Grammarly ad from [Yuriy Timen](https://flight.beehiiv.net/v2/clicks/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJodHRwczovL3R3aXR0ZXIuY29tL1RoZVRpbWVuYXRvcj91dG1fc291cmNlPW5ld3NsZXR0ZXIudmlkdGFvLmNvbSZ1dG1fbWVkaXVtPXJlZmVycmFsJnV0bV9jYW1wYWlnbj10b3AtOS1ob29rLXRhY3RpY3Mtd2UtZXh0cmFjdGVkLWZyb20tdG9kYXktcy10b3AtZGlyZWN0LXJlc3BvbnNlLXlvdXR1YmUtYWRzIiwicG9zdF9pZCI6IjZiNmI5NzkzLTMwZjktNGYxYi05MGM2LTczNjAxMjViMjdjZiIsInB1YmxpY2F0aW9uX2lkIjoiNTE1MjcxMWItMjFmNy00ZTkwLWI4NTgtMzM5YTZmNDJiNzJjIiwidmlzaXRfdG9rZW4iOiI5ZTI1ZDE3ZC1iNzc2LTQyY2YtYmNjYi1iZWUzYjU3ODEzMGYiLCJpYXQiOjE3MDExNTY5MzIsImlzcyI6Im9yY2hpZCJ9.l6E653agcDMW9SIKTSSz6OoeoV_a22RoUOxjrhexUMg) with over FIFTY THREE MILLION in estimated adspend:",
    videoId: "NvyXsBIlYHw",
    promoMarkdown: `Ad: [https://youtu.be/NvyXsBIlYHw](https://youtu.be/NvyXsBIlYHw)\\
    \\
    See [all of Grammarly’s ads & landing pages](https://flight.beehiiv.net/v2/clicks/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJodHRwczovL2FwcC52aWR0YW8uY29tLyMvZGFzaGJvYXJkL2VudGl0eS1pbmZvP2JyYW5kSWQ9NDE4NjM1JmtleXdvcmQ9R3JhbSIsInBvc3RfaWQiOiI2YjZiOTc5My0zMGY5LTRmMWItOTBjNi03MzYwMTI1YjI3Y2YiLCJwdWJsaWNhdGlvbl9pZCI6IjUxNTI3MTFiLTIxZjctNGU5MC1iODU4LTMzOWE2ZjQyYjcyYyIsInZpc2l0X3Rva2VuIjoiOWUyNWQxN2QtYjc3Ni00MmNmLWJjY2ItYmVlM2I1NzgxMzBmIiwiaWF0IjoxNzAxMTU2OTMyLCJpc3MiOiJvcmNoaWQifQ.ATYGAnkzqdfsNk_2sNZhMUCw2ShgXKXM2fV5Q72CvtE) here inside VidTao 2.0.`
  }, {
    title: "🔬 Hook Tactic #9: Visual Focus on Product Features",
    howToMarkdown: "Use close-up visuals to emphasize key product features such as comfort or ease of use.",
    inActionMarkdown: "Watch how this ad from CoziSlides (with $848k+ in adspend), “the world’s thickest massage sandals, that feel like a beach holiday for your feet:",
    videoId: "l7yrqHM6c_k",
    promoMarkdown: `Ad: [https://youtu.be/l7yrqHM6c_k](https://youtu.be/l7yrqHM6c_k)\\
    \\
    See [all of CoziSlides’ ads & landing pages here inside VidTao 2.0](https://flight.beehiiv.net/v2/clicks/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJodHRwczovL2FwcC52aWR0YW8uY29tLyMvZGFzaGJvYXJkL2VudGl0eS1pbmZvP2JyYW5kSWQ9MjEzMDA1JmtleXdvcmQ9Q296aXNsaWRlcyIsInBvc3RfaWQiOiI2YjZiOTc5My0zMGY5LTRmMWItOTBjNi03MzYwMTI1YjI3Y2YiLCJwdWJsaWNhdGlvbl9pZCI6IjUxNTI3MTFiLTIxZjctNGU5MC1iODU4LTMzOWE2ZjQyYjcyYyIsInZpc2l0X3Rva2VuIjoiOWUyNWQxN2QtYjc3Ni00MmNmLWJjY2ItYmVlM2I1NzgxMzBmIiwiaWF0IjoxNzAxMTU2OTMyLCJpc3MiOiJvcmNoaWQifQ.lQ-Iql7-RK_FmDIT_qWbB3gfyXGzOmzQwL2isl3NjC4)`
  }
];
