import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from "react-redux";
import useThunk from '../../hooks/use-thunks';
import { getAnalysisReport } from '../../store';
import AnalysisSection from './AnalysisSection';

function AnalysisPage() {
  const { id } = useParams();
  const analysis = useSelector(state => state.analysis);
  const [doGetAnalysisReport] = useThunk(getAnalysisReport);

  useEffect(() => {
    doGetAnalysisReport(id);
  }, [doGetAnalysisReport, id]);

  let content = "";

  if (analysis.isWaiting) {
    content = (
      <p className="mt-8">Loading...</p>
    );
  } else if (analysis.errorMessage) {
    content = (
      <p className="mt-8 text-xl">{analysis.errorMessage}</p>
    );
  } else if (analysis.isReportLoaded) {
    content = <AnalysisSection />
  }
  
  return(
    <div className="my-2">
      {content}
    </div>
  );
}

export default AnalysisPage;
