const MAX_STEPS = 2

function LandingBox({ children, step }) {
  step = Math.max(Math.min(step, MAX_STEPS), 0)
  const stepLights = "🟩".repeat(step) + "⬜".repeat(MAX_STEPS - step)

  return (
    <div className="px-1 sm:px-2 md:px-8 py-2 my-4 max-w-4xl w-full bg-[#F3F3F3] border border-[#595959] rounded-[26px] text-center">
      <p className="text-xl tracking-widest">{stepLights}</p>
      <p className="text-xl">Step {step} of {MAX_STEPS}</p>
      <>{children}</>
    </div>
  );
}

export default LandingBox
