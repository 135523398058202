import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { landingReducer } from './slices/landingSlice';
import { analysisReducer } from './slices/analysisSlice';

export const store = configureStore({
  reducer: {
    landing: landingReducer,
    analysis: analysisReducer
  }
});

setupListeners(store.dispatch);

export { landingNextStep } from './slices/landingSlice';
export * from './thunks/submitEmail'
export * from './thunks/getTopSimilarAdVideos';
export * from './thunks/buildReport';
export * from './thunks/getAnalysisReport'
