import classNames from 'classnames';

function Button({
  children,
  primary,
  rounded,
  disabled,
  className,
  ...rest
}) {
  const classes = classNames(
    'flex items-center px-6 py-5 h-8',
    {
      'bg-[#305BE8] text-white': primary,
      'rounded-xl': rounded,
      'bg-gray-400 text-white': disabled
    },
    className
  );

  return (
    <button {...rest} disabled={disabled} className={classes}>
      {children}
    </button>
  );
}

export default Button;
