import { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { landingNextStep, submitEmail, getTopSimilarAdVideos, buildReport } from '../../store';
import useThunk from '../../hooks/use-thunks';
import VidtaoButton from '../../components/VidtaoButton';
import { LANDING_STEP } from '../../constants'
import Heading1 from '../../components/Heading1';
import LandingBox from './landingBox';
import InputBox from '../../components/InputBox';
import VideoDetailsCheckbox from "../analysisPage/VideoDetailsCheckbox";

function LandingPage() {
  const queryParameters = new URLSearchParams(window.location.search)
  const uid = queryParameters.get('uid');
  const email = queryParameters.get('email');

  const dispatch = useDispatch();
  const [doSubmitEmail] = useThunk(submitEmail);
  const [doGetTopSimilarAdVideos] = useThunk(getTopSimilarAdVideos);
  const [doBuildReport] = useThunk(buildReport);
  const landing = useSelector(state => state.landing);
  // const [email, setEmail] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [selectedVideoId, setSelectedVideoId] = useState('');
  const inputRef = useRef(null);

  const handleIntroSubmit = event => {
    event.preventDefault();
    dispatch(landingNextStep());
  };

  const checkUser = useCallback(() => {
    doSubmitEmail({ uid, email });
  }, [doSubmitEmail, uid, email]);

  const handleUrlSubmit = event => {
    event.preventDefault();
    if (videoUrl.trim()) {
      doGetTopSimilarAdVideos({ uid, email, videoUrl });
    }
  };

  const handleBuildReport = event => {
    event.preventDefault();
    if (selectedVideoId) {
      doBuildReport({ uid, email, videoUrl: videoUrl, videoId: selectedVideoId })
    }
  };

  useEffect(() => {
    if (landing.currentStep === LANDING_STEP.LOADING) {
      if (uid && email) {
        checkUser();
      }
    }
  }, [checkUser, landing.currentStep, uid, email]);

  let content = ""

  if (landing.currentStep === LANDING_STEP.LOADING) {
    if (uid && email) {
      content = (
        <div className="mt-2 mb-4">
          {landing.errorMessage ? <Heading1>{landing.errorMessage}</Heading1> : "Please wait..."}
        </div>
      )
    } else {
      content = <Heading1>Please visit <a className="text-blue-600" href="https://app.vidtao.com/">VidTao</a> and sign up first.</Heading1>
    }
  } else if (landing.currentStep === LANDING_STEP.INTRO) {
    content = (
      <>
        <Heading1 className="mt-2 mb-4 sm:mb-8 md:w-[32rem]">What’s Your YouTube Ad <span className="underline">Creative Score</span>?</Heading1>
        <p className="text-lg md:text-2xl mb-6">See how you stack up against top ads in your market & discover your YouTube ad’s…</p>
        <div className="text-xl max-w-3xl">
          <p className="mb-2">✅ <span className="font-bold underline">Skip Shield Score</span>: How well does your ad grab attention in the first 5 seconds?</p>
          <p className="mb-2">⚓ <span className="font-bold underline">Attention Anchor Rating</span>: How well does your ad do in seconds 5-10?</p>
          <p className="mb-2">🎯 <span className="font-bold underline">Persuasion Score</span>: Your ad’s overall effectiveness</p>
          <p>It’s <span className="underline">100% Free</span> & we’ll give you a <span className="font-bold">detailed, custom 19 page analysis</span> packed with actionable insights you can use to improve your ad’s performance, right away.</p>
        </div>
        <form className="my-10" onSubmit={handleIntroSubmit}>
          <VidtaoButton>Get My Score</VidtaoButton>
        </form>
      </>
    );
  // } else if (landing.currentStep === LANDING_STEP.EMAIL_INPUT) {
  //   content = (
  //     <LandingBox step={1}>
  //       <Heading1 className="mt-2 mb-4">Where Can We Send Your YouTube Ad Creative Score & Analysis?</Heading1>
  //       <p className="text-lg md:text-2xl mb-6">We’re building you a <span className="underline">custom, 19-page YouTube ad score & analysis doc</span>. Where can we send it? 👇</p>
  //       <form onSubmit={handleEmailSubmit}>
  //         <InputBox id="email" type="email" className="w-full text-sm md:text-md lg:text-xl" placeholder="Enter your best email here…" value={email} onChange={event => setEmail(event.target.value)} required />
  //         <VidtaoButton className="mx-auto my-10" disabled={landing.isWaiting}>Next Step...</VidtaoButton>
  //       </form>
  //       {landing.errorMessage && <p className="mb-4 text-red-500 text-left">{landing.errorMessage}</p>}
  //     </LandingBox>
  //   );
  } else if (landing.currentStep === LANDING_STEP.URL_INPUT) {
    content = (
      <LandingBox step={1}>
        <Heading1 className="mt-2 mb-4">Enter your YouTube Ad’s url…</Heading1>
        <p className="text-lg md:text-2xl mb-6">Grab your YouTube video ad’s url and paste it in the box below:👇</p>
        <form onSubmit={handleUrlSubmit}>
          <div className="relative w-full mb-8">
            <InputBox id="video-url" ref={inputRef} className="w-full text-sm md:text-md lg:text-xl text-center" value={videoUrl} onChange={event => setVideoUrl(event.target.value)} required />
            {videoUrl.length > 0 ||
            <div className="absolute inset-2 cursor-text flex justify-center items-center" onClick={() => inputRef.current.focus()}>
              <p className="text-sm md:text-md lg:text-xl text-center">Enter your <span className="font-bold">YouTube ad video url here</span> & click “Get My Score” to begin</p>
            </div>}
          </div>
          <VidtaoButton className="mx-auto my-10" disabled={landing.isWaiting}>Next Step...</VidtaoButton>
        </form>
        {landing.errorMessage && <p className="mb-4 text-red-500 text-left">{landing.errorMessage}</p>}
      </LandingBox>
    );
  } else if (landing.currentStep === LANDING_STEP.SELECT_COMPARISON_AD) {
    content = (
      <LandingBox step={2}>
        <Heading1 className="mt-2 mb-4">Select a Top-Performing Competitor Ad</Heading1>
        <div className="text-left">
          <p className="text-lg md:text-2xl mb-6"><span className="font-bold">How does your ad compare to other high-performing ads in your market?</span> Below are 5 proven, high-performing YouTube ads <span className="underline">that are most similar to your ad</span>.</p>
          <p className="text-lg md:text-2xl mb-6 font-bold">Which one do you want to compare your ad with?</p>
          <p className="text-lg md:text-2xl mb-6 font-bold">Select one and click “Get My Score” and we’ll start generating your report & analysis! 👇</p>
          {landing.similarAdVideos && <>
            {landing.similarAdVideos[0].map((adVideo, index) => {
              return (<div key={index}>
                <VideoDetailsCheckbox adVideo={adVideo} name="video-id" onChange={event => setSelectedVideoId(event.target.value)} />
              </div>);
            })}

            {landing.similarAdVideos[1].length > 0 && <>
              <p className="text-lg my-5">Other ads from this channel</p>
              {landing.similarAdVideos[1].map((adVideo, index) => {
                return (<div key={index}>
                  <VideoDetailsCheckbox adVideo={adVideo} name="video-id" onChange={event => setSelectedVideoId(event.target.value)} />
                </div>);
              })}
            </>}
          </>}
          <form className="mt-6 mb-10" onSubmit={handleBuildReport}>
            <VidtaoButton className="mx-auto" disabled={landing.isWaiting || !selectedVideoId}>Get My Score</VidtaoButton>
          </form>
        </div>
        {landing.errorMessage && <p className="mb-4 text-red-500 text-left">{landing.errorMessage}</p>}
      </LandingBox>
    );
  } else if (landing.currentStep === LANDING_STEP.BUILDING_REPORT) {
    content = (
      <>
        <Heading1 className="mt-2 mb-4">Thanks! Give us 5-10 minutes to build your 19 page custom YouTube ad creative analysis</Heading1>
        <div className="mt-6 max-w-3xl">
          <p className="text-lg md:text-2xl mb-6">We’re scoring your ad & building your 19-page YouTube ad score & analysis report right now as we speak…</p>
          <p className="text-lg md:text-2xl mb-6">In the meantime, want to check out our library of <span className="underline">over 7 million unlisted YouTube ads? (and landing pages)</span></p>
          <p className="text-lg md:text-2xl">Click below to check out VidTao for free:</p>
        </div>
        <a href="https://app.vidtao.com/?source=creative-analysis-app-post-optin" target="_blank" rel="noreferrer" className="my-10"><VidtaoButton>Try VidTao</VidtaoButton></a>
      </>
    );
  } else if (landing.currentStep === LANDING_STEP.REPORT_AVAILABLE) {
    content = (
      <>
        <Heading1 className="mt-2 mb-4">Your report has already been built. Please check your email.</Heading1>
      </>
    );
  }

  return (
    <>{content}</>
  );
}

export default LandingPage;
