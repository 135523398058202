module.exports.LANDING_STEP = {
  LOADING: -1,
  INTRO: 0,
  // EMAIL_INPUT: 1,
  URL_INPUT: 1,
  SELECT_COMPARISON_AD: 2,
  BUILDING_REPORT: 3,
  REPORT_AVAILABLE: 4
}

module.exports.CONFIG = {
  THEME_COLOR: '#305BE8'
};

module.exports.SERVER_API = {
  BASE_URL: 'https://creativescore.api.vidtao.com'
};
