import classNames from 'classnames';

function SubHeadline({ children, className, ...rest }) {
  const classes = classNames("text-xl font-bold text-[#2D2DC6]", className);

  return (
    <h2 className={classes} {...rest}>{children}</h2>
  );
}

export default SubHeadline;
